/**
 * src/js/main.js
 *
 * Main javascript file
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @author Carsten Coull <c.coull@agentur-halma.de>
 * @package halma-kickstart
 * @version 2021-05-28
 */

/**
 * Import modules, modules are stored in `src/js/modules/`
 */
// import Nav from "/dist/js/modules/nav.js";
// import LazyLoading from "/dist/js/modules/lazyload.js";
// import Map from "/dist/js/modules/map.js";
// import Slider from "/dist/js/modules/slider.js";
// import FlashMessages from "/dist/js/modules/flash-messages.js";
// import LightBox from "/dist/js/modules/light-box.js";
// import Overlay from "/dist/js/modules/overlay.js";
// Ideas for further modules
// - Slider / Carousel
// - Lightbox
// - Overlay

/**
 * Application main class
 */
class MAIN {
	/**
	 * Constructor
	 */
	constructor() {
		// Class properties, can only be declared inside a method … :-(
		this.debug = false;
		document.addEventListener("DOMContentLoaded", () => this.init());
		window.onload = () => this.pageInit();
	}

	/**
	 * init
	 * Called on DOMContentLoaded, so a good place to setup things not dependend
	 * on the page finished rendering
	 */
	init() {
		if (this.debug) {
			console.debug("MAIN::init");
		}

		document.body.classList.add("page-has-loaded");

		// get scrollbar width to be able to use .full-width
		// @see https://destroytoday.com/blog/100vw-and-the-horizontal-overflow-you-probably-didnt-know-about
		const scrollbarWidth = window.innerWidth - document.body.clientWidth;
		document.body.style.setProperty("--scrollbarWidth", scrollbarWidth + "px");

		window.addEventListener("resize", () => this.throttle(this.resizeHandler), { passive: true });
		window.addEventListener("scroll", () => this.throttle(this.scrollHandler), { passive: true });
	}

	/**
	 * pageInit
	 * Called on window.load, i.e. when the page and all assets have been
	 * loaded completely and the page has rendered
	 *
	 * @return void
	 */
	pageInit() {

		// console.log('fitting text');
		// const b1 = document.querySelector(".brand--gong");
		// const b2 = document.querySelector(".brand--operator");
		// window.fitText(b1);
		// window.fitText(b2);

		if (this.debug) {
			console.debug("MAIN::pageInit");
		}

		document.body.classList.add("page-has-rendered");

		// Let's see if we have a header element and get it's height (for
		// detecting scroll past header, see `App.scrollHandler`
		this.header = document.querySelector("header");
		if (this.header) {
			let rect = this.header.getBoundingClientRect();
			this.headerBottom = rect.top + rect.height;
		}

		this.main();

		const factor = 5;

		function animate() {
			const colors = [
				'#0a360d',
				'#0d1321',
				'#1d2d44',
				'#24243b',
				'#3e5c76',
				'#472D2D',
				'#553939',
				'#56346a',
				'#7B2869',
				'#872819',
				'#c5321f'
			];
			let color = colors[Math.floor(Math.random() * colors.length)];

			const bgEl = document.querySelector('.wrapper');
			const anim = bgEl.animate([
				{ backgroundColor: color }
			], {
				easing: 'ease-in-out',
				duration: 1 * factor * 1000,
				iterations: 1,
				fill: 'forwards'
			});

			anim.onfinish = () => {
				window.setTimeout(animate, 3 * factor * 1000);
			}
		}

		animate();
	}



	/**
	 * Main method
	 * Put main business logic here
	 *
	 * @return void
	 */
	main() {
		const legalTrigger = document.querySelector('[href="#legal"]');
		const legalCloseBtn = document.getElementById('legal-close-btn');
		const id = legalTrigger.getAttribute('href').slice(1);
		const legal = document.getElementById(id);
		const legalHeight = legal.offsetHeight;
		const wrapper = document.querySelector('.wrapper');
		const timing = {
			duration: 350,
			easing: 'ease-in-out',
		};

		var legalIsOpen = false;

		legalTrigger.addEventListener('click', e => {
			e.preventDefault();
			if (legalIsOpen) {
				return;
			}
			const keyframes = {
				transform: ['translateY(0px)', `translateY(-${legalHeight}px)`]
			};
			const anim = wrapper.animate(keyframes, timing);
			anim.onfinish = () => {
				wrapper.style.transform = `translateY(-${legalHeight}px)`;
				legalIsOpen = true;
			};
		});

		legalCloseBtn.addEventListener('click', e => {
			const anim = wrapper.animate({ transform: [`translateY(-${legalHeight}px)`, 'translatey(0px)']}, timing);
			anim.onfinish = () => {
				wrapper.style.transform = '';
				legalIsOpen = false;
			};
		});





		var points = [
			[0, 20],
			[100, 30],
			[100, 60],
			[0, 90]
		];
		const shape = document.getElementById('shape');
		var oldClipPath = shape.style.cssText;
        const bgPhoto = document.querySelector('.bg-photo');

		function animateShape() {
			requestAnimationFrame(() => {
				for (var i = 0; i < 4; i++) {

					var rand = Math.random();
					var dist = Math.random() * 100;

					if (rand > 0.5) {
						dist *= -1;
					}

					points[i][1] += dist;
					if (points[i][1] > 90) {
						points[i][1] = 90;
					}
					if (points[i][1] < 10) {
						points[i][1] = 10;
					}

					const mindist = 30;
					if (points[0][1] > points[3][1] - mindist) {
						points[0][1] = points[3][1] - mindist;
					}
					if (points[3][1] < points[0][1] + mindist) {
						points[3][1] = points[0][1] + mindist;
					}
					if (points[1][1] > points[2][1] - mindist) {
						points[1][1] = points[2][1] - mindist;
					}
					if (points[2][1] < points[1][1] + mindist) {
						points[2][1] = points[1][1] + mindist;
					}
				}


				let t1 = getRandomNumberBetween(5000, 30000)
				let newClipPath = `polygon(${points[0][0]}% ${points[0][1]}%, ${points[1][0]}% ${points[1][1]}%, ${points[2][0]}% ${points[2][1]}%, ${points[3][0]}% ${points[3][1]}%)`;
				const anim = shape.animate({
					clipPath: [ oldClipPath,  newClipPath]
				}, {
					duration: t1,
					easing: 'ease-in-out',
					fill: 'forwards'
				});
				oldClipPath = newClipPath;

				const min = 3000, max = 10000;
				let t2 = getRandomNumberBetween(min, max)
				anim.onfinish = () => {
					setTimeout(animateShape, t2);
				};


			});


		}

		animateShape();

			function getRandomNumberBetween(min, max) {
				return Math.floor(Math.random() * (max - min + 1) + min);
			}

        function nextKenBurns() {
            var origX = getRandomNumberBetween(0, 100);
            var origY = getRandomNumberBetween(0, 100);
            console.log(origX, origY);
            bgPhoto.style.transformOrigin = `${origX}% ${origY}%`;
            const bgPhotoAnim = bgPhoto.animate({
                transform: [ 'scale(1.0)', 'scale(4.0)' ]
            }, {
                duration: 100 * 1000,
                iterations: 2,
                direction: 'alternate',
                easing: 'ease-in-out',
                fill: 'both'
            });
            bgPhotoAnim.onfinish = () => {
                console.log('next kb');
                nextKenBurns()
            };
        }
        console.log('go...');
        nextKenBurns();
	}



	/*
	 * Debounced / throttled scroll handler. Put your scroll-related stuff here!
	 * @return void
	 */
	scrollHandler() {
		let y = window.scrollY;

		if (this.debug) {
			console.debug(`Scroll position: ${y}`);
		}

		// Set classes on body depending on how far the page has scrolled
		document.body.classList.toggle("has-scrolled", y > 0);
		document.body.classList.toggle("has-scrolled-a-bit", y > 30);
		document.body.classList.toggle(
			"has-scrolled-past-header",
			y > this.headerBottom
		);
		document.body.classList.toggle(
			"has-scrolled-100vh",
			y > window.innerHeight
		);

		// Todo: Scroll direction!
		if (this.lastScrollPosition) {
			document.body.classList.toggle(
				"has-scrolled-up",
				y < this.lastScrollPosition
			);

			document.body.classList.toggle(
				"has-scrolled-down",
				y > this.lastScrollPosition
			);
		}

		// Extend here …
		const threshold = 50;



		this.lastScrollPosition = y;
	}

	/**
	 * Debounced / throttled scroll handler. Put your resize-related stuff here!
	 *
	 * @return void
	 */
	resizeHandler() {
		let width = window.innerWidth,
			height = window.innerHeight;

		if (this.debug) {
			console.debug(`Window has been resized to ${width}, ${height}`);
		}

		// Set custom properties
		document.body.style.setProperty("--window-width", `${width}px`);
		document.body.style.setProperty("--window-height", `${height}px`);

		// Extend here …
	}

	/**
	 * Throttler method
	 *
	 * @param callable: Handler to be called on throttled scroll event
	 * @return void
	 */
	throttle(handler) {
		this.ticking = false;

		if (!this.ticking) {
			window.requestAnimationFrame(() => {
				handler.call(this);
				this.ticking = false;
			});
			this.ticking = true;
		}
	}

	/**
	 * execute if click/interaktion is outside of the Selector
	 *
	 * @param selector: Element selector (e.g. class, id) to watch for
	 * @param callback: function to execute if interaktion is outside of the Selector
	 *
	 * Example:
	 * this.app.onOutsideClick('#main-menu', this.closeMenu);
	 */
	onOutsideClick(selector, callback) {
		document.addEventListener("click", (event) => {
			var target = event.target.parentNode.closest(selector);
			if (target == null) {
				callback(event, target);
			}
		});
	}

	hideElement() {

	}

}

new MAIN();
